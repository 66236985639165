import { createRouter, createWebHistory } from "vue-router";
import HomeComp from '../components/HomeComp.vue';
import DetailComp from '../components/DetailComp.vue';

const routes = [
    {path: '/', name: 'Home', component: HomeComp},
    {path: '/:slug', name: 'Detail', component: DetailComp},
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
<script setup>
</script>

<template>
    <footer>
        <div>
            <img src="@/assets/images/logo_alternative.svg" />
        </div>
        <p>where2.watch ® 2023 | All rights reserved.</p>
        <small>You can only get information through this website. You can watch the series and movies you searched on their own platforms.</small>
    </footer>
</template>

<style lang="scss">
    footer {
        display: flex;
        flex-direction: column;
        margin-top: 150px;

        div {
            img {
                max-width: 145px;
                opacity: .5;

                -webkit-transition: opacity 500ms ease-out;
                -moz-transition: opacity 500ms ease-out;
                -o-transition: opacity 500ms ease-out;
                transition: opacity 500ms ease-out;

                &:hover {
                    opacity: 1;
                }
            }
        }

        p {
            font-size: 16px;
            color: #fff;
            font-weight: 500;
            margin: 20px 0 0;
        }

        small {
            font-size: 14px;
            font-weight: 400;
            color: rgba(255, 255, 255, .5);
        }
    }
</style>

<script setup>
    import { ref } from 'vue'
    import { useRoute } from "vue-router";

    const data = ref(null)
    const error = ref(null)

    fetch(
        process.env.VUE_APP_API_URL + "titles/" + useRoute().params.slug
    )
      .then((res) => res.json())
      .then((json) => (data.value = json))
      .catch((err) => (error.value = err));

</script>
<template>
    <section v-if="data !== null">
        <div class="poster">
            <img :src="data.poster" :alt="data.name" />
        </div>
        <div class="content">
            <h1>
                {{ data.name }}
                <strong>{{ data.imdbScore }}</strong>
            </h1>
            <div class="mini-info">
                <p>
                    <span><strong>Year:</strong> {{ data.year }}</span>
                    <span><strong>Min. Age:</strong> {{ data.minAge }}</span>
                    <span><strong>Duration:</strong> {{ data.duration }}</span>
                </p>
            </div>
            <div class="description">
                <p>{{ data.description }}</p>
            </div>
            <div class="platforms-wrapper">
                <h2>Platforms</h2>
                <div class="platforms">
                    <div class="platform" v-for="(platform, index) in data.platforms" v-bind:key="index">
                        <a :href="platform.url" target="_blank">
                            <div class="icon">
                                <img :src="require(`@/assets/images/${platform.name}_logo.svg`)" />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss">
    $badge-bg-color: #ffde00;
    $badge-text-color: #25142d;

    section {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 40px;

        .poster {
            border-radius: 10px;
            overflow: hidden;
            width: 250px;

            img {
                max-width: 100%;
            }
        }

        .content {
            flex: 1;
            text-align: left;
            color: #fff;

            h1 {
                display: flex;
                gap: 15px;
                align-items: center;
                font-size: 22px;
                color: #fff;
                font-weight: 700;
                margin: 0;
                padding: 0;

                strong {
                    background-color: $badge-bg-color;
                    color: $badge-text-color;
                    border-radius: 10px;
                    font-weight: 700;
                    text-align: center;
                    padding: 4px;
                    width: 40px;
                    font-size: 16px;
                }
            }
            .mini-info {
                p {
                    color: rgba(255, 255, 255, .5);
                    font-size: 12px;
                    margin: 5px 0;
                    padding: 0;
                    display: flex;
                    gap: 15px;

                    span {

                        &::after {
                            padding-left: 15px;
                            content: '|';
                        }

                        &:last-child {
                            &::after{
                                content: '';
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
            .description {
                font-size: 14px;
            }
            .platforms-wrapper {
                display: flex;
                flex-direction: column;

                h2 {
                    font-size: 20px;
                }
                .platforms {
                    display: flex;
                    gap: 5px;

                    .platform {
                        a {
                            opacity: 0.7;

                            .icon {
                                height: 36px;
                                border-radius: 18px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 4px 10px;
                                background: #fff;
                                overflow: hidden;

                                img {
                                    max-width: 100%;
                                    max-height: 70%;
                                }

                            }

                            -webkit-transition: opacity 300ms ease-out;
                            -moz-transition: opacity 300ms ease-out;
                            -o-transition: opacity 300ms ease-out;
                            transition: opacity 300ms ease-out;

                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
</style>

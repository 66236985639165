<script setup>
    import { ref, watch } from 'vue'

    const query = ref(null)
    const showResults = ref(false)

    const data = ref(null)
    const error = ref(null)

    watch(query, (newQuery) => {
        if (newQuery.length > 0) {
            showResults.value = true;

          fetch(
              process.env.VUE_APP_API_URL + "titles?" + new URLSearchParams({
              q: newQuery
            })
          )
            .then((res) => res.json())
            .then((json) => (data.value = json))
            .catch((err) => (error.value = err));

        } else {
            showResults.value = false;
        }
    })

</script>

<template>
    <div class="search-wrapper">
        <div class="search-inner">
            <input type="text" name="q" placeholder="Start typing the movie or TV show you want to search..." autocomplete="off" v-model="query" />
        </div>
        <div class="search-results" v-if="showResults">
            <div class="list">
                <ul>
                    <li v-for="item in data" v-bind:key="item.id">
                        <RouterLink :to="{name: 'Detail', params: { slug: item.slug }}">
                            <div class="imdb-score">{{ item.imdbScore }}</div>
                            <div class="name">{{ item.name }}</div>
                            <div class="icons">
                                <div class="icon">
                                    <img :src="require(`@/assets/images/${item.type}-icon.svg`)" />
                                </div>
                                <div class="platforms" v-for="(platform, index) in item.platforms" v-bind:key="index">
                                    <div class="icon">
                                        <img :src="require(`@/assets/images/${platform.name}_logo.svg`)" />
                                    </div>
                                </div>
                            </div>
                        </RouterLink>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
$input-border-color: #70507f;
$input-border-radius: 10px;
$input-background-color: #34223d;

$badge-bg-color: #ffde00;
$badge-text-color: #25142d;

.search-wrapper {

    margin-top: 60px;
    position: relative;
    width: 100%;

  .search-inner {
    input {
      border: 1px solid $input-border-color;
      border-radius: $input-border-radius;
      background-color: $input-background-color;
      width: 100%;
      padding: 16px;
      font-size: 24px;
      color: #fff;
      outline: none;
      font-family: 'Poppins', sans-serif;
      font-weight: 300;

      &::placeholder {
        font-style: italic;
      }
    }

  }

  .search-results {
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;
    background-color: $input-background-color;

    position: absolute;
    z-index: 9999;
    width: 100%;
    text-align: left;
    margin-top: 10px;
    max-height: 300px;
    overflow-y: auto;

    .list {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
               a {
                display: flex;
                align-items: center;
                gap: 10px;
                padding: 16px;
                border-bottom: 1px dashed rgba(255, 255, 255, .1);
                color: #fff;
                font-size: 20px;
                text-decoration: none;

                -webkit-transition: background-color 200ms ease-out;
                -moz-transition: background-color 200ms ease-out;
                -o-transition: background-color 200ms ease-out;
                transition: background-color 200ms ease-out;

                &:hover {
                    background-color: rgba(255, 255, 255, .05);
                }

                .imdb-score {
                    background-color: $badge-bg-color;
                    color: $badge-text-color;
                    border-radius: $input-border-radius;
                    font-weight: 700;
                    text-align: center;
                    padding: 4px;
                    width: 50px;
                }

                .name {
                    flex: 1;
                }

                .icons {
                    display: flex;
                    gap: 5px;

                    .icon {
                        height: 36px;
                        border-radius: 18px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 4px 10px;
                        background: #fff;
                        overflow: hidden;

                        img {
                            max-width: 100%;
                            max-height: 70%;
                        }

                    }

                    .platforms {
                        display: flex;
                        gap: 10px;
                    }

                }
               }
               &:last-child {
                    a {
                        border: 0;
                    }
                }
            }
        }
    }
  }
}
</style>

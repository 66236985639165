<template>
  <div class="container">
    <HeaderComp />
    <router-view />
    <FooterComp />
  </div>
</template>

<script>
import HeaderComp from './components/HeaderComp.vue'
import FooterComp from './components/FooterComp.vue'

export default {
  name: 'App',
  components: {
    HeaderComp,
    FooterComp
  }
}
</script>

<style lang="scss">
$background-color: #25142d;

$small: 576px;
$medium: 768px;
$large: 992px;
$x-large: 1200px;
$xx-large: 1400px;

$container-extra-small: 100%;
$container-small: 540px;
$container-medium: 720px;
$container-large: 960px;
$container-x-large: 1140px;
$container-xx-large: 1320px;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  background-color: $background-color;
}

* {
  box-sizing: border-box;
}

#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 100px;

.container {
  
  @media screen and (max-width: $small) {
    width: $container-extra-small;
  }

  @media screen and (min-width: $small) and (max-width: $medium) {
    width: $container-small;
  }

  @media screen and (min-width: $medium) and (max-width: $large) {
    width: $container-medium;
  }

  @media screen and (min-width: $large) and (max-width: $x-large) {
    width: $container-large;
  }

  @media screen and (min-width: $x-large) and (max-width: $xx-large) {
    width: $container-x-large;
  }

  @media screen and (min-width: $xx-large) {
    width: $container-xx-large;
  }

  margin: 0 auto;
  position: relative;

  section {
    width: 100%;
  }
}

}
</style>

<template>
    <header>
        <div class="logo">
            <RouterLink to="/">
                <img src="@/assets/images/logo.svg" />
            </RouterLink>
        </div>
    </header>
</template>

<style lang="scss">
    header {
        .logo a img {
            -webkit-transition: transform 500ms ease-out;
            -moz-transition: transform 500ms ease-out;
            -o-transition: transform 500ms ease-out;
            transition: transform 500ms ease-out;
            width: 430px; max-width: 60%;

            &:hover {
                transform: scale(1.05);
            }
        }
    }
</style>